import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'

export async function login(req) {
  req.platform = "ADMIN"
  req.fromIp = "0.0.0.0"

  return apiClient
    .post(urls.auth.login, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}

export async function logout() {
  return apiClient
    .post(urls.auth.logout)
    .then((response) => {
      return !!response
    })
    .catch(() => console.log())
}
