import { all, call, put, takeEvery } from 'redux-saga/effects'

import * as loggingService from 'services/logging'
import actions from 'redux/logging/actions'
import LogLines from 'model/logging/LogLines'
import { downloadFile } from 'helpers/file'
import SearchCurrentReq from 'model/logging/req/SearchCurrentReq'

export function* DOWNLOAD_CURRENT() {
  yield put({
    type: 'logging/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(loggingService.downloadCurrent)
  if (data) {
    downloadFile(data.fileType, data.base64, data.fileName)
  }

  yield put({
    type: 'logging/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DOWNLOAD_ARCHIVED({ payload }) {
  yield put({
    type: 'logging/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { name } = payload
  const data = yield call(loggingService.downloadArchived, name)
  if (data) {
    downloadFile(data.fileType, data.base64, data.fileName)
  }

  yield put({
    type: 'logging/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LIST_ARCHIVED() {
  yield put({
    type: 'logging/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(loggingService.listArchived)
  if (data) {
    yield put({
      type: 'logging/SET_STATE',
      payload: {
        archivedList: data
      }
    })
  }

  yield put({
    type: 'logging/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_CURRENT({ payload }) {
  yield put({
    type: 'logging/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { page } = payload
  const data = yield call(loggingService.readCurrentLog, page)
  if (data) {
    yield put({
      type: 'logging/SET_STATE',
      payload: {
        logLines: LogLines.fromData(data)
      }
    })
  }

  yield put({
    type: 'logging/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SEARCH_CURRENT({ payload }) {
  yield put({
    type: 'logging/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(loggingService.searchCurrentLog, SearchCurrentReq.of(payload))
  if (data) {
    yield put({
      type: 'logging/SET_STATE',
      payload: {
        logLines: LogLines.fromData(data)
      }
    })
  }

  yield put({
    type: 'logging/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.DOWNLOAD_CURRENT, DOWNLOAD_CURRENT),
    takeEvery(actions.DOWNLOAD_ARCHIVED, DOWNLOAD_ARCHIVED),
    takeEvery(actions.LIST_ARCHIVED, LIST_ARCHIVED),
    takeEvery(actions.READ_CURRENT, READ_CURRENT),
    takeEvery(actions.SEARCH_CURRENT, SEARCH_CURRENT),
  ])
}
