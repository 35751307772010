import { all, call, put, takeEvery } from 'redux-saga/effects'

import * as statisticsService from 'services/statistics'
import actions from 'redux/statistics/actions'
import OrderStatistics from 'model/statistics/OrderStatistics'

export function* GET_ORDER_STATISTICS({ payload }) {
  yield put({
    type: 'statistics/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(statisticsService.getOrderStatisticsForAll, payload)
  if (data) {
    yield put({
      type: 'statistics/SET_STATE',
      payload: {
        order: OrderStatistics.from(data.statistics),
      },
    })
  }

  yield put({
    type: 'statistics/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ORDER_STATISTICS, GET_ORDER_STATISTICS),
  ])
}
