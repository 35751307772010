import localeAntd from 'antd/es/locale/tr_TR'

const messages = {
  'app.name': 'Toptancı Satış Sistemi',

  'notification.info': 'Bilgi',
  'notification.error': 'Hata',
  'notification.warn': 'Uyarı',
  'notification.message.successLogin': 'Giriş başarıyla gerçekleştirildi.',
  'notification.message.successOperation': 'İşlem başarıyla gerçekleştirildi.',
  'notification.message.checkMail': 'Mail kutunuzu kontrol ediniz.',
  'notification.message.invalidForm': 'Form bilgileri düzgün değildir.',
  'notification.message.successUpload': 'Dosya başarıyla yüklenmiştir.',
  'notification.message.failedUpload': 'Dosya yükleme aşamasında hata oluşmuştur.',

  'action.title': 'Operasyon',
  'action.create': 'Ekle',
  'action.update': 'Güncelle',
  'action.delete': 'Sil',
  'action.search': 'Ara',
  'action.reset': 'Reset',
  'action.clear': 'Temizle',
  'action.detail': 'Detay',
  'action.enable': 'Aktifleştir',
  'action.disable': 'Pasifleştir',
  'action.lock': 'Kilitle',
  'action.unlock': 'Kilidi Aç',
  'action.upload': 'Yükle',
  'action.complete': 'Sonlandır',
  'action.export': 'Çıktı Al',
  'action.settings': 'Ayarlar',
  'action.deliver': 'Kargoya ver',
  'action.yes': 'Evet',
  'action.ok': 'Tamam',
  'action.no': 'İptal',
  'action.show': 'Göster',
  'action.cancel': 'İptal Et',
  'action.sure': 'Silmek istediğinize emin misiniz?',
  'action.sure.complete': 'Sonlandırmak istediğinize emin misiniz?',

  'common.label.emptyTable': 'Kayıtlı veri bulunmamaktadır',
  'common.label.drag.title': 'Yüklemek için dosyayı bu alana sürükleyin veya tıklayın',
  'common.label.drag.note': 'Tek veya çoklu dosya seçilebilmektedir',
  'common.label.filter': 'Filtreler',
  'common.label.export': 'Çıktı Al',
  'common.label.all': 'Hepsi',
  'common.select.choose': 'Lütfen seçiniz',
  'common.select.active': 'Aktif',
  'common.select.passive': 'Pasif',
  'common.role.SUPERADMIN': 'Super Admin',
  'common.role.ADMIN': 'Admin',
  'common.role.SALESMAN': 'Satışcı',
  'common.role.CUSTOMER': 'Müşteri',
  'common.lang.TR': 'Türkçe',
  'common.lang.EN': 'İngilizce',
  'common.platform.ADMIN': 'Admin Paneli',
  'common.platform.ECOMMERCE': 'e-Ticaret Sitesi',
  'common.platform.ANDROID': 'Android Mobil Uygulama',
  'common.platform.IOS': 'iOS Mobil Uygulama',
  'common.platform.WEB': 'Website',
  'common.bool.true': 'Evet',
  'common.bool.false': 'Hayır',
  'common.or': 'veya',
  'common.noResult': 'Sonuç yok',
  'common.clipboard': 'Tıkladığınız bilgi kopyalandı.',

  'breadcrumb.home': 'Ana Sayfa',

  'topBar.profileMenu.hello': 'Merhaba',
  'topBar.profileMenu.role': 'Yetki',
  'topBar.profileMenu.username': 'Kullanıcı Adı',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.editProfile': 'Profil Ayarları',
  'topBar.profileMenu.logout': 'Çıkış',

  'auth.label.forgotPassword': 'Şifremi unuttum?',
  'auth.label.register': 'Hesabınızı oluşturun',
  'auth.label.contractRegister':
    'Devam ettiğinizde TSS Kullanım Koşullarını kabul etmiş sayılırsınız.',
  'auth.label.alreadyRegister': 'Zaten üye misiniz?',
  'auth.label.notRegister': 'Üyeliğiniz yok mu?',
  'auth.label.login': 'Sisteme Giriş Yapın',
  'auth.button.forgotPassword': 'Şifremi Sıfırla',
  'auth.button.login': 'Giriş',
  'auth.button.register': 'Kayıt ol',
  'auth.link.register': 'Kayıt olun',
  'auth.input.email.error': 'Lütfen email adresinizi giriniz',
  'auth.input.email.placeholder': 'Email',
  'auth.input.username.error': 'Lütfen kullanıcı adınızı giriniz',
  'auth.input.username.placeholder': 'Kullanıcı Adı',
  'auth.input.password.error': 'Lütfen şifrenizi giriniz',
  'auth.input.password.placeholder': 'Şifre',
  'auth.401.title': 'Yetkiniz bulunmamaktadır',
  'auth.401.desc': 'Aradığınız sayfa için yetkiniz bulunmamaktadır.',
  'auth.401.back': 'Geri',
  'auth.404.title': 'Sayfa bulunumadı',
  'auth.404.desc': 'Aradığınız sayfa silinmiş olabilir.',
  'auth.404.back': 'Geri',
  'auth.500.title': 'Sistem Hatası',
  'auth.500.desc': 'Beklenmeyen bir hata oluştu.',
  'auth.500.back': 'Geri',

  'customer.label.id': 'Id',
  'customer.label.companyName': 'Şirket Adı',
  'customer.label.salesmanNote': 'Satıcı Notu',
  'customer.label.currentCode': 'Cari Kodu',
  'customer.label.firstName': 'İsim',
  'customer.label.lastName': 'Soyisim',
  'customer.label.email': 'Email',
  'customer.label.phoneNo': 'Telefon Numarası',
  'customer.label.city': 'Şehir',
  'customer.label.town': 'İlçe',
  'customer.label.district': 'Mahalle',
  'customer.label.branch': 'Şube',
  'customer.label.fullAddress': 'Adres',
  'customer.label.relatedUser': 'İlişkili Kullanıcı',
  'customer.label.authorizedUserList': 'Yetkili Kullanıcı Listesi',
  'customer.label.authorizedUser': 'Yetkili Kullanıcı',
  'customer.label.communicationAllowed': 'İletişim izni',
  'customer.label.groupId': 'Grup Id',
  'customer.label.group': 'Grup',
  'customer.label.status': 'Durum',
  'customer.label.exportFormat': 'Çıktı Formatı',
  'customer.search.placeholder': 'Ara (Şirket Adı)',
  'customer.title.companyInformation': 'Şirket Bilgileri',
  'customer.title.personalInformation': 'Kişisel Bilgiler',
  'customer.title.addressInformation': 'Adres Bilgileri',
  'customer.title.userInformation': 'Kullanıcı Bilgileri',
  'customer.title.permissionInformation': 'Yetkiler',
  'customer.select.exportFormat.XLS': 'Excel',
  'customer.select.status.ACTIVE': 'Aktif',
  'customer.select.status.PASSIVE': 'Pasif',
  'customer.input.companyName.placeholder': 'Şirket adı',
  'customer.input.salesmanNote.placeholder': 'Satıcı notu (1, 2, 3...)',
  'customer.input.currentCode.placeholder': 'Cari Kodu',
  'customer.input.firstName.placeholder': 'İsim',
  'customer.input.lastName.placeholder': 'Soyisim',
  'customer.input.email.placeholder': 'Email',
  'customer.input.phoneNo.placeholder': 'Telefon numarası',
  'customer.input.city.placeholder': 'Şehir',
  'customer.input.town.placeholder': 'İlçe',
  'customer.input.district.placeholder': 'Mahalle',
  'customer.input.branch.placeholder': 'Şube',
  'customer.input.fullAddress.placeholder': 'Adres',
  'customer.input.relatedUser.placeholder': 'İlişkili kullanıcı adı',
  'customer.input.authorizedUserList.placeholder': 'Yetkili kullanıcı adı listesi',
  'customer.input.group.placeholder': 'Grup',
  'customer.input.exportFormat.placeholder': 'Çıktı Formatı',
  'customer.input.companyName.error': 'Lütfen şirket adını giriniz',
  'customer.input.salesmanNote.error': 'Lütfen satıcı notunu giriniz',
  'customer.input.currentCode.error': 'Lütfen cari kodu giriniz',
  'customer.input.firstName.error': 'Lütfen isim giriniz',
  'customer.input.lastName.error': 'Lütfen soyisim giriniz',
  'customer.input.email.error': 'Lütfen email giriniz',
  'customer.input.city.error': 'Lütfen şehir giriniz',
  'customer.input.relatedUser.error': 'Lütfen ilişkili kullanıcı adını giriniz',
  'customer.input.authorizedUserList.error':
    'Lütfen en az bir tane yetkili kullanıcı adını giriniz',
  'customer.input.exportFormat.error': 'Lütfen çıktı formatı seçiniz!',
  'customer.tooltip.companyName': 'Şirket adı ile müşteri arayabilirsiniz.',
  'customer.button.new': 'Müşteri Ekle',

  'customerGroup.label.id': 'Id',
  'customerGroup.label.name': 'Grup Adı',
  'customerGroup.label.description': 'Açıklama',
  'customerGroup.label.exportFormat': 'Çıktı Formatı',
  'customerGroup.search.placeholder': 'Ara (Grup adı)',
  'customerGroup.title.groupInformation': 'Grup Bilgileri',
  'customerGroup.select.exportFormat.XLS': 'Excel',
  'customerGroup.input.name.placeholder': 'Grup Adı',
  'customerGroup.input.exportFormat.placeholder': 'Çıktı Formatı',
  'customerGroup.input.name.error': 'Lütfen grup adını giriniz',
  'customerGroup.input.description.placeholder': 'Açıklama',
  'customerGroup.input.description.error': 'Lütfen açıklama giriniz',
  'customerGroup.input.exportFormat.error': 'Lütfen çıktı formatı seçiniz!',
  'customerGroup.tooltip.name': 'İsim ile grup arayabilirsiniz.',
  'customerGroup.button.new': 'Müşteri Grubu Ekle',

  'user.label.username': 'Kullanıcı Adı',
  'user.label.password': 'Şifre',
  'user.label.firstName': 'İsim',
  'user.label.lastName': 'Soyisim',
  'user.label.primaryEmail': 'Email',
  'user.label.phoneNo': 'Telefon Numarası',
  'user.label.alias': 'Takma İsim',
  'user.label.showAlias': 'Takma isimi göster',
  'user.label.status': 'Durum',
  'user.label.cartAllowed': 'Sepet özelliği aktif olsun mu?',
  'user.label.hidePrice': 'Fiyat bilgisi saklansın mı?',
  'user.label.hideAvailability': 'Stokta bulunma bilgisi saklansın mı?',
  'user.label.hideDiscount': 'İskonto bilgisi saklansın mı?',
  'user.label.hideOpenCompetition': 'Rekabete açık olma bilgisi saklansın mı?',
  'user.label.hideCampaign': 'Kampanyalı ürünler saklasın mı?',
  'user.label.hideFuture': 'Yakında gelecek ürünler saklasın mı?',
  'user.label.hideRecent': 'Yeni ürünler saklasın mı?',
  'user.label.hideLedSearch': 'Led arama saklasın mı?',
  'user.label.filterIfStockOut': 'Stokta olmayan ürünler filtrelensin mi?',
  'user.label.filterIfOpenCompetition': 'Rekabete açık olan ürünler filtrelensin mi?',
  'user.label.allowMarketplaceExport': 'Ürün bilgisini toplu olarak dışarıya aktarabilsin mi?',
  'user.label.disabledCategoryList': 'Gizlenmek istenen kategori listesi',
  'user.label.role': 'Rol',
  'user.label.tabs.editPersonal': 'Kişisel Bilgiler',
  'user.label.tabs.editRole': 'Rol Bilgileri',
  'user.label.tabs.editPassword': 'Şifre Bilgileri',
  'user.label.tabs.cartSettings': 'Sepet Ayarları',
  'user.label.tabs.productSettings': 'Ürün Ayarları',
  'user.label.tabs.categorySettings': 'Kategori Ayarları',
  'user.search.placeholder': 'Ara (Kullanıcı adı)',
  'user.title.coreInformation': 'Temel Bilgiler',
  'user.title.personalInformation': 'Kişisel Bilgiler',
  'user.title.permissionInformation': 'İzinler',
  'user.title.roleInformation': 'Rol Bilgileri',
  'user.title.enableForm': 'Kullanıcı Aktifleştirme',
  'user.input.username.placeholder': 'Kullanıcı Adı',
  'user.input.password.placeholder': 'Şifre',
  'user.input.firstName.placeholder': 'İsim',
  'user.input.lastName.placeholder': 'Soyisim',
  'user.input.primaryEmail.placeholder': 'Email',
  'user.input.phoneNo.placeholder': 'Telefon numarası',
  'user.input.alias.placeholder': 'Takma isim',
  'user.input.disabledCategoryList.placeholder': 'Kategori',
  'user.input.role.placeholder': 'Rol',
  'user.input.username.error': 'Lütfen kullanıcı adı giriniz',
  'user.input.password.error': 'Lütfen şifre giriniz',
  'user.input.firstName.error': 'Lütfen isim giriniz',
  'user.input.lastName.error': 'Lütfen soyisim giriniz',
  'user.input.primaryEmail.error': 'Lütfen email giriniz',
  'user.input.disabledCategoryList.error': 'Lütfen kategori seçiniz',
  'user.input.role.error': 'Lütfen rol seçiniz',
  'user.select.status.ENABLED': 'Aktif',
  'user.select.status.LOCKED': 'Kilitli',
  'user.select.status.DISABLED': 'Pasif',
  'user.tooltip.username': 'Kullanıcı adı ile kullanıcı arayabilirsiniz.',
  'user.button.new': 'Kullanıcı Ekle',
  'user.button.enable': 'Aktifleştir',

  'category.label.id': 'Id',
  'category.label.name': 'İsim',
  'category.label.desc': 'Açıklama',
  'category.label.prio': 'Öncelik',
  'category.label.hidden': 'Kategori gizlensin mi?',
  'category.label.parentId': 'Üst Kategori',
  'category.label.exportFormat': 'Çıktı Formatı',
  'category.search.placeholder': 'Ara (Ana Kategori Adı)',
  'category.select.exportFormat.XLS': 'Excel',
  'category.title.coreInformation': 'Temel Bilgiler',
  'category.input.name.placeholder': 'İsim',
  'category.input.desc.placeholder': 'Açıklama',
  'category.input.prio.placeholder': 'Öncelik',
  'category.input.exportFormat.placeholder': 'Çıktı Formatı',
  'category.input.parentId.placeholder': 'Üst Kategori',
  'category.input.name.error': 'Lütfen isim alanını doldurunuz!',
  'category.input.desc.error': 'Lütfen açıklama alanını doldurunuz!',
  'category.input.prio.error': 'Lütfen öncelik alanını doldurunuz!',
  'category.input.parentId.error': 'Lütfen üst kategori seçiniz!',
  'category.input.exportFormat.error': 'Lütfen çıktı formatı seçiniz!',
  'category.tooltip.name': 'İsim ile ürün kategorisi arayabilirsiniz.',
  'category.button.new': 'Kategori Ekle',

  'brand.label.id': 'Id',
  'brand.label.name': 'İsim',
  'brand.label.own': 'Şirket Markası',
  'brand.label.logo': 'Logo',
  'brand.label.existedLogo': 'Var Olan Logo',
  'brand.search.placeholder': 'Ara (Marka Adı)',
  'brand.title.coreInformation': 'Temel Bilgiler',
  'brand.input.name.placeholder': 'İsim',
  'brand.input.name.error': 'Lütfen isim alanını doldurunuz!',
  'brand.tooltip.name': 'İsim ile ürün markası arayabilirsiniz.',
  'brand.button.new': 'Marka Ekle',

  'product.label.id': 'Id',
  'product.label.idList': 'Id Listesi',
  'product.label.codeList': 'Kod Listesi',
  'product.label.barcode': 'Barkod',
  'product.label.serialNo': 'Seri Numarası',
  'product.label.relatedCode': 'Bağlı Ürün Kodu',
  'product.label.available': 'Mevcut Durumu',
  'product.label.shortDesc': 'Kısa Açıklama',
  'product.label.desc': 'Açıklama',
  'product.label.exportFormat': 'Çıktı Formatı',
  'product.label.select.exportFormat.XLS': 'Excel',
  'product.label.select.hidden': 'Gizlilik Durumu',
  'product.label.select.hidden.true': 'Gizli ürünleri listele',
  'product.label.select.hidden.false': 'Görünür ürünleri listele',
  'product.label.select.available': 'Stok Durumu',
  'product.label.select.available.true': 'Mevcut ürünleri listele',
  'product.label.select.available.false': 'Mevcut olmayan ürünleri listele',
  'product.label.select.openCompetition': 'Rekabete Açık Olma Durumu',
  'product.label.select.openCompetition.true': 'Rekabete açık ürünleri listele',
  'product.label.select.openCompetition.false': 'Rekabete kapalı ürünleri listele',
  'product.label.select.ignoreInventory': 'Stok Takip Durumu',
  'product.label.select.ignoreInventory.true': 'Stok takibi olmayan ürünleri listele',
  'product.label.select.ignoreInventory.false': 'Stok takibi olan ürünleri listele',
  'product.label.select.campaign': 'Kampanyalı Ürün',
  'product.label.select.campaign.true': 'Kampanyalı ürünleri listele',
  'product.label.select.campaign.false': 'Kampanyalı olmayan ürünleri listele',
  'product.label.select.recent': 'Yeni Ürün',
  'product.label.select.recent.true': 'Yeni ürünleri listele',
  'product.label.select.recent.false': 'Yeni olmayan ürünleri listele',
  'product.label.select.bestSeller': 'Çok Satılan Ürün',
  'product.label.select.bestSeller.true': 'Çok satılan ürünleri listele',
  'product.label.select.bestSeller.false': 'Çok satılan olmayan ürünleri listele',
  'product.label.select.vitrine': 'Vitrin Ürünü',
  'product.label.select.vitrine.true': 'Vitrin ürünleri listele',
  'product.label.select.vitrine.false': 'Vitrin olmayan ürünleri listele',
  'product.label.select.future': 'Yakında Ürünleri',
  'product.label.select.future.true': 'Yakında ürünleri listele',
  'product.label.select.future.false': 'Yakında olmayan ürünleri listele',
  'product.label.select.soldOut': 'Nesli Tükenen Ürünler',
  'product.label.select.soldOut.true': 'Nesli tükenen ürünleri listele',
  'product.label.select.soldOut.false': 'Nesli tükenen olmayan ürünleri listele',
  'product.label.checkbox.openCompetition': 'Rekabete açık olsun',
  'product.label.checkbox.available': 'Ürün mevcut mu',
  'product.label.checkbox.allowAddToCart': 'Sepete eklenebilsin',
  'product.label.checkbox.ignoreInventory': 'Stok takibi olmasın',
  'product.label.checkbox.bestSeller': 'En çok satanlar etiketi',
  'product.label.checkbox.vitrine': 'Vitrin etiketi',
  'product.label.checkbox.recent': 'Yeni etiketi',
  'product.label.checkbox.future': 'Yakında gelecek etileti',
  'product.label.checkbox.soldOut': 'Nesli tükendi etiketi',
  'product.label.checkbox.campaign': 'Kampanya etiketi',
  'product.label.brandId': 'Marka',
  'product.label.code': 'Kod',
  'product.label.name': 'İsim',
  'product.label.price': 'Fiyat',
  'product.label.isAvailable': 'Mevcut ürünler',
  'product.label.categoryId': 'Kategori',
  'product.label.priceAmount': 'Fiyat',
  'product.label.priceCurr': 'Döviz Tipi',
  'product.label.type': 'Tür',
  'product.label.boxAmount': 'Kutu Adeti',
  'product.label.boxAmountDesc': 'İç Kutu Adeti',
  'product.label.minQuantityInCart': 'Sepet için Minumum Adet',
  'product.label.bestSellerPrio': 'En çok satanlar (Sıra)',
  'product.label.vitrinePrio': 'Vitrin (Sıra)',
  'product.label.recentPrio': 'Yeni (Sıra)',
  'product.label.futurePrio': 'Yakında gelecek (Sıra)',
  'product.label.soldOutPrio': 'Nesli tükendi (Sıra)',
  'product.label.campaignPrio': 'Kampanya (Sıra)',
  'product.label.category': 'Kategori',
  'product.label.categoryPrio': 'Kategori içindeki Önceliği',
  'product.label.brand': 'Marka',
  'product.label.brandPrio': 'Marka içindeki Önceliği',
  'product.label.hidden': 'Gizlilik Durumu',
  'product.label.hidden.true': 'Gizli',
  'product.label.hidden.false': 'Görünür',
  'product.label.hiddenReason': 'Gizlilik Nedeni',
  'product.label.hiddenReason.NOT_IMPORTED_YET': "İthalat'ı Yapılmayacak",
  'product.label.hiddenReason.WILL_NOT_SUPPLIED': 'Tedarik Edilmeyecek',
  'product.label.hiddenReason.NONE': '-',
  'product.label.tagList': 'Etiketler',
  'product.label.relatedItemList': 'Muadil Ürünler',
  'product.search.placeholder': 'Ara (Ürün kodu, açıklama veya tag)',
  'product.title.coreInformation': 'Temel Bilgiler',
  'product.title.priceInformation': 'Ücret Bilgileri',
  'product.title.categoryAndBrandInformation': 'Kategori & Marka Bilgileri',
  'product.title.typeInformation': 'Tür Bilgileri',
  'product.title.hiddenInformation': 'Gizlilik Bilgileri',
  'product.title.settingsInformation': 'Ayarlar',
  'product.title.relationInformation': 'İlişkiler',
  'product.title.mediaInformation': 'Media',
  'product.input.idList.placeholder': 'Ürün id listesi giriniz',
  'product.input.codeList.placeholder': 'Ürün kodu listesi giriniz',
  'product.input.categoryId.placeholder': 'Kategori seçiniz',
  'product.input.brandId.placeholder': 'Marka seçiniz',
  'product.input.code.placeholder': 'Ürün kodu',
  'product.input.name.placeholder': 'Ürün ismi',
  'product.input.barcode.placeholder': 'Ürün barkodu',
  'product.input.serialNo.placeholder': 'Ürün seri numarası',
  'product.input.desc.placeholder': 'Ürün açıklaması',
  'product.input.shortDesc.placeholder': 'Ürün kısa açıklaması',
  'product.input.priceAmount.placeholder': 'Ürün fiyatı',
  'product.input.priceCurr.placeholder': 'TRY',
  'product.input.type.placeholder': 'Ürün türü',
  'product.input.boxAmount.placeholder': 'Kutu adeti',
  'product.input.boxAmountDesc.placeholder': 'İç kutu adeti (Örneğin 100/1000)',
  'product.input.hidden.placeholder': 'Gizlilik durumu',
  'product.input.hiddenReason.placeholder': 'Gizlilik nedeni',
  'product.input.minQuantityInCart.placeholder': 'Sepet eklenebilmesi için gereken minumum adet',
  'product.input.bestSellerPrio.placeholder': 'Sıra',
  'product.input.vitrinePrio.placeholder': 'Sıra',
  'product.input.recentPrio.placeholder': 'Sıra',
  'product.input.futurePrio.placeholder': 'Sıra',
  'product.input.soldOutPrio.placeholder': 'Sıra',
  'product.input.campaignPrio.placeholder': 'Sıra',
  'product.input.tagList.placeholder': 'Etiketler',
  'product.input.relatedItemList.placeholder': 'Muadil Ürünler',
  'product.input.category.placeholder': 'Kategori seçiniz',
  'product.input.categoryPrio.placeholder': 'Kategori önceliği',
  'product.input.brand.placeholder': 'Marka seçiniz',
  'product.input.brandPrio.placeholder': 'Marka önceliği',
  'product.input.exportFormat.placeholder': 'Çıktı Formatı',
  'product.input.externalMedia.url.placeholder': 'Youtube Video URL',
  'product.input.externalMedia.sortIndex.placeholder': 'Video Sıralaması',
  'product.input.code.error': 'Lütfen ürün kodunu giriniz',
  'product.input.name.error': 'Lütfen ürün ismini giriniz',
  'product.input.desc.error': 'Lütfen ürün açıklamasını giriniz',
  'product.input.priceAmount.error': 'Lütfen ürün fiyatını giriniz',
  'product.input.priceCurr.error': 'Lütfen ürün döviz tipini giriniz',
  'product.input.type.error': 'Lütfen ürün türünü giriniz',
  'product.input.hidden.error': 'Lütfen gizlilik durumunu seçiniz',
  'product.input.hiddenReason.error': 'Lütfen gizlilik nedenini seçiniz',
  'product.input.boxAmount.error': 'Lütfen kutu adetini giriniz',
  'product.input.boxAmountDesc.error': 'Lütfen iç kutu adet bilgisini giriniz',
  'product.input.category.error': 'Lütfen kategori seçiniz',
  'product.input.categoryPrio.error': 'Lütfen kategori önceliği giriniz',
  'product.input.brand.error': 'Lütfen marka seçiniz',
  'product.input.brandPrio.error': 'Lütfen marka önceliği  giriniz',
  'product.input.exportFormat.error': 'Lütfen çıktı formatı seçiniz!',
  'product.input.externalMedia.url.error': 'Youtube URL bilgisini giriniz!',
  'product.input.externalMedia.sortIndex.error': 'Video sıralamasını giriniz!',
  'product.select.type.PHYSICAL': 'Fiziksel Ürün',
  'product.select.type.DIGITAL': 'Dijital Ürün',
  'product.desc.available.true': 'Mevcut',
  'product.desc.available.false': 'Yok',
  'product.tooltip.code': 'Ürünleri kod ile arayabilirsiniz.',
  'product.button.new': 'Ürün Ekle',
  'product.media.button.addItem': 'Video Ekle',

  'led.label.code': 'Ürün Kodu',
  'led.label.originalCode': 'Orjinal Kodu',
  'led.label.chinaCode': 'Çin Kodu',
  'led.label.firm': 'Firma',
  'led.label.firmCode': 'Firma Kodu',
  'led.label.ledCount': 'Led Sayısı',
  'led.label.ledLength': 'Led Uzunluğu',
  'led.label.ledLengthStart': 'Led Uzunluğu (Min)',
  'led.label.ledLengthEnd': 'Led Uzunluğu (Max)',
  'led.label.tvModel': 'Tv Modeli',
  'led.label.tvPanel': 'Tv Paneli',
  'led.label.tvSize': 'Tv Büyüklüğü',
  'led.label.tvBrand': 'Tv Markası',
  'led.label.ledDetail': 'Adet - Uzunluk',
  'led.label.addFirmCode': 'Firma Kodu Ekle',
  'led.search.placeholder': 'Ara (Ürün Kodu)',
  'led.title.coreInformation': 'Temel Bilgiler',
  'led.title.firmInformation': 'Firma Kodu Bilgileri',
  'led.title.detailInformation': 'Led Bilgileri',
  'led.title.tvInformation': 'Tv Bilgileri',
  'led.input.code.placeholder': 'Ürün kodu',
  'led.input.firm.placeholder': 'Firma seçiniz',
  'led.input.firmCode.placeholder': 'Firma kodu',
  'led.input.ledCount.placeholder': 'Led adeti',
  'led.input.ledLength.placeholder': 'Led uzunluğu',
  'led.input.originalCode.placeholder': 'Orjinal kodu',
  'led.input.chinaCode.placeholder': 'Çin kodu',
  'led.input.tvBrand.placeholder': 'Tv markası seçiniz',
  'led.input.tvModel.placeholder': 'Tv modeli giriniz',
  'led.input.tvPanel.placeholder': 'Tv paneligiriniz',
  'led.input.tvSize.placeholder': 'Tv büyüklüğü',
  'led.input.code.error': 'Lütfen ürün kodunu giriniz',
  'led.input.ledCount.error': 'Lütfen led adetini giriniz',
  'led.input.ledLength.error': 'Lütfen led uzunluğunu giriniz',
  'led.input.firm.error': 'Lütfen firma seçiniz',
  'led.input.firmCode.error': 'Lütfen firma kodu giriniz',
  'led.tooltip.code': 'Led ürünlerini kod ile arayabilirsiniz.',
  'led.button.new': 'Led Ürün Ekle',

  'bulk.led.load': 'Toplu Ekleme İşlemi',
  'bulk.discount.load': 'Toplu İskonto Ekleme İşlemi',
  'bulk.product.legacy.load': 'Toplu Güncelleme İşlemi',
  'bulk.product.create.load': 'Toplu Ürün Ekleme',
  'bulk.sampleExcel': '(Örnek Excel - XLS)',
  'bulk.product.migrate': 'Tek Ürün Ekleme (Eski sistemden yeni sisteme)',

  'audit.label.correlationId': 'İstek Numarası',
  'audit.label.begin': 'Başlangıç Tarihi',
  'audit.label.end': 'Bitiş Tarihi',
  'audit.label.username': 'Kullanıcı Adı',
  'audit.label.platform': 'Platform',
  'audit.label.appVersion': 'Uygulama Versiyonu',
  'audit.label.clientVersion': 'Önyüz Versiyonu',
  'audit.label.dateTime': 'Zaman',
  'audit.label.isFailed': 'Hatalı İşlem',
  'audit.label.failedReason': 'Hata Nedeni',
  'audit.label.client': 'IP',
  'audit.label.url': 'URL',
  'audit.label.lang': 'Dil',
  'audit.label.reqBody': 'İstek',
  'audit.label.resBody': 'Cevap',
  'audit.tooltip.correlationId': 'Audit kayıtlarını istek numarası ile arayabilirsiniz.',

  'fault.label.correlationId': 'İstek Numarası',
  'fault.label.begin': 'Başlangıç Tarihi',
  'fault.label.end': 'Bitiş Tarihi',
  'fault.label.username': 'Kullanıcı Adı',
  'fault.label.platform': 'Platform',
  'fault.label.environment': 'Ortam',
  'fault.label.appVersion': 'Uygulama Versiyonu',
  'fault.label.dateTime': 'Zaman',
  'fault.label.isUncaught': 'Beklenmedik Hata',
  'fault.label.isSystem': 'Sistem Hatası',
  'fault.label.lang': 'Dil',
  'fault.label.error': 'Hata Detayı',
  'fault.tooltip.correlationId': 'Hata kayıtlarını istek numarası ile arayabilirsiniz.',

  'news.label.id': 'Id',
  'news.label.title': 'Haber Başlığı',
  'news.label.content': 'Haber İçeriği',
  'news.label.beginDate': 'Başlangıç Tarihi',
  'news.label.untilDate': 'Bitiş Tarihi',
  'news.label.prio': 'Öncelik',
  'news.label.beginStart': 'Başlangıç Tarihi Verilen Tarihten Sonra Olanlar',
  'news.label.beginEnd': 'Başlangıç Tarihi Verilen Tarihten Önce Olanlar',
  'news.label.untilStart': 'Bitiş Tarihi Verilen Tarihten Sonra Olanlar',
  'news.label.untilEnd': 'Bitiş Tarihi Verilen Tarihten Önce Olanlar',
  'news.search.placeholder': 'Ara (Başlık veya İçerik)',
  'news.tooltip.title': 'Haberleri başlık ile arayabilirsiniz.',
  'news.button.new': 'Haber Ekle',
  'news.title.coreInformation': 'Temel Bilgiler',
  'news.input.title.placeholder': 'Haber başlığı',
  'news.input.content.placeholder': 'Haber içeriği',
  'news.input.beginDate.placeholder': 'Haber başlangıç tarihi',
  'news.input.untilDate.placeholder': 'Haber bitiş tarihi',
  'news.input.prio.placeholder': 'Haber önceliği',
  'news.input.title.error': 'Lütfen haber başlığını giriniz',
  'news.input.content.error': 'Lütfen haber içeriği giriniz',
  'news.input.beginDate.error': 'Lütfen haberin başlama tarihini seçiniz',
  'news.input.prio.error': 'Lütfen haber önceliğini seçiniz',

  'cache.label.name': 'İsim',
  'cache.label.count': 'Adet',

  'toggle.label.key': 'Key',
  'toggle.label.value': 'Key Değeri',
  'toggle.tooltip.title': '',
  'toggle.button.new': 'Toggle Ekle',
  'toggle.title.coreInformation': 'Temel Bilgiler',
  'toggle.input.title.error': 'Lütfen key bilgisini giriniz',
  'toggle.input.title.placeholder': 'DB_BACKUP_ENABLED',
  'toggle.input.content.error': 'Lütfen seçiniz',

  'about.label.id': 'Id',
  'about.label.companyName': 'Şirket İsmi',
  'about.label.address': 'Adres',
  'about.label.phoneNo': 'Telefon Numarası',
  'about.label.faxNo': 'Fax Numarası',
  'about.label.email': 'Email',
  'about.label.bankAccountNote': 'IBAN Bilgileri',
  'about.label.googleMap': 'Google Map',
  'about.label.defaultt': 'Varsayılan',
  'about.title.coreInformation': 'Temel Bilgiler',
  'about.title.socialAccountInformation': 'Sosya Hesap Bilgileri',
  'about.input.companyName.placeholder': 'Şirket İsmi',
  'about.input.address.placeholder': 'Adres',
  'about.input.phoneNo.placeholder': 'Telefon Numarası',
  'about.input.faxNo.placeholder': 'Fax Numarası',
  'about.input.email.placeholder': 'Email',
  'about.input.bankAccountNote.placeholder': 'IBAN bilgilerini giriniz',
  'about.input.socialAccountType.placeholder': 'Sosyal Hesap',
  'about.input.socialAccountUrl.placeholder': 'Url',
  'about.input.googleMap.placeholder': 'Google Map',
  'about.input.defaultt.placeholder': 'Varsayılan',
  'about.input.companyName.error': 'Lütfen şirket ismini giriniz',
  'about.input.address.error': 'Lütfen adres giriniz',
  'about.input.phoneNo.error': 'Lütfen telefon numarasını giriniz',
  'about.input.faxNo.error': 'Lütfen fax numarasını giriniz',
  'about.input.email.error': 'Lütfen email adresini giriniz',
  'about.input.socialAccountType.error': 'Sosyal hesap seçilmelidir',
  'about.input.socialAccountUrl.error': "Sosyal hesabın url'ini giriniz",
  'about.button.new': 'Hakkında Ekle',
  'about.button.addSocialAccount': 'Sosyal Hesap Ekle',

  'order.label.code': 'Kod',
  'order.label.tenantCode': 'Şirket Sipariş Kodu',
  'order.label.user': 'Kullanıcı',
  'order.label.customer': 'Müşteri',
  'order.label.orderDate': 'Tarih',
  'order.label.orderDateFinished': 'Sonlanma Tarihi',
  'order.label.charge': 'Tutar',
  'order.label.note': 'Not',
  'order.label.paymentNote': 'Ödeme Notu',
  'order.label.delivery': 'Gönderim Şekli',
  'order.label.orderCode': 'Sipariş No',
  'order.label.inventoryRegion': 'Depo',
  'order.label.platform': 'Platform',
  'order.label.processStatus': 'Sipariş Durumu',
  'order.label.processStatus.ORDERED': 'Sipariş Alındı',
  'order.label.processStatus.DELIVERED': 'Gönderildi',
  'order.label.processStatus.CANCELLED': 'İptal Edildi',
  'order.label.curr': 'Döviz Birimi',
  'order.label.subTotal': 'Ara Toplam',
  'order.label.total': 'Toplam',
  'order.label.product': 'Ürün',
  'order.label.discount': 'İskonto',
  'order.label.quantity': 'Adet',
  'order.title.orderInformation': 'Sipariş Bilgileri',
  'order.title.productInformation': 'Sepet Bilgileri',
  'order.input.user.placeholder': 'Kullanıcı seçiniz',
  'order.input.customer.placeholder': 'Müşteri seçiniz',
  'order.input.note.placeholder': 'Not',
  'order.input.paymentNote.placeholder': 'Ödeme Not',
  'order.input.product.placeholder': 'Ürün',
  'order.input.quantity.placeholder': 'Adet',
  'order.input.discountAmountPercentage.placeholder': 'İskonto (%)',
  'order.input.delivery.placeholder': 'Gönderim Şekli',
  'order.input.user.error': 'Lütfen kullanıcı seçiniz',
  'order.input.customer.error': 'Lütfen müşteri seçiniz',
  'order.input.product.error': 'Lütfen ürün seçiniz',
  'order.input.quantity.error': 'Lütfen adet giriniz',
  'order.input.delivery.error': 'Lütfen gönderim şekli seçiniz',
  'order.button.addItem': 'Ürün Ekle',
  'order.button.new': 'Sipariş Ekle',

  'delivery.label.id': 'Id',
  'delivery.label.name': 'İsim',
  'delivery.label.deliveryType': 'Teslimat Tipi',
  'delivery.label.prio': 'Öncelik',
  'delivery.search.placeholder': 'Ara (İsim)',
  'delivery.select.deliveryType.CARGO': 'Kargo',
  'delivery.select.deliveryType.WAREHOUSE': 'Liman',
  'delivery.select.deliveryType.LOGISTIC': 'Lojistik',
  'delivery.select.deliveryType.CAR': 'Araba',
  'delivery.tooltip.name': 'Teslimatı isim ile arayabilirsiniz.',
  'delivery.button.new': 'Teslimat Ekle',
  'delivery.title.coreInformation': 'Temel Bilgiler',
  'delivery.input.name.placeholder': 'Teslimat ismi',
  'delivery.input.deliveryType.placeholder': 'Teslimat tipi',
  'delivery.input.prio.placeholder': 'Öncelik',
  'delivery.input.name.error': 'Lütfen teslimat ismini giriniz',
  'delivery.input.deliveryType.error': 'Lütfen teslimat tipini seçiniz',
  'delivery.input.prio.error': 'Lütfen öncelik değerini giriniz',

  'discount.label.id': 'Id',
  'discount.label.customer': 'Müşteri',
  'discount.label.customerGroup': 'Müşteri Grubu',
  'discount.label.product': 'Ürün',
  'discount.label.category': 'Kategori',
  'discount.label.owner': 'Müşteri - Ürün',
  'discount.label.discountAmount': 'İskonto Değeri',
  'discount.label.discountType': 'İskonto Tipi',
  'discount.label.minBoxAmount': 'Minimum Koli Adeti',
  'discount.search.placeholder': 'Ara (Ürün kodu, Kategori adı, Müşteri adı veya Müşteri grubu)',
  'discount.title.ownerInformation': 'Temel Bilgiler',
  'discount.title.discountInformation': 'İskonto Bilgileri',
  'discount.input.customer.placeholder': 'Müşteri seçiniz',
  'discount.input.customerGroup.placeholder': 'Müşteri grubu seçiniz',
  'discount.input.product.placeholder': 'Ürün seçiniz',
  'discount.input.category.placeholder': 'Kategori seçiniz',
  'discount.input.discountAmount.placeholder': 'İskonto değeri',
  'discount.input.discountType.placeholder': 'İskonto tipi',
  'discount.input.minBoxAmount.placeholder': 'Minimum koli adet',
  'discount.input.customer.error': 'Müşteri veya Müşteri Grubu seçiniz',
  'discount.input.discountAmount.error': 'Lütfen iskonto değerini giriniz',
  'discount.input.discountTyipe.error': 'Lütfen iskonto tipi seçiniz',
  'discount.button.new': 'İskonto Ekle',
  'discount.info.pleaseSearch': 'İskontoları görmek için lütfen ilk önce filtreleyiniz.',
  'discount.select.discountType.PERCENTAGE': "Yüzde",
  'discount.select.discountType.AMOUNT': "Tutar",
  'discount.select.discountType.FIXED': "Sabit Tutar",
  'discount.filter.allCustomer': 'Tüm Müşteriler',
  'discount.filter.allProduct': 'Tüm Ürünler',
  'discount.tooltip.name': 'Ürün kodu, kategori adı, müşteri adı veya müşteri grubu ile iskonto arayabilirsiniz.',

  'defective.label.id': 'Id',
  'defective.label.claimDate': 'Tarih',
  'defective.label.gtClaimDate': 'Seçilen Tarihten Sonra',
  'defective.label.ltClaimDate': 'Seçilen Tarihten Önce',
  'defective.label.reporterName': 'Müşteri Adı',
  'defective.label.productCode': 'Ürün Kodu',
  'defective.label.processStatus': 'Arızalı Durumu',
  'defective.label.itemCount': 'Adet',
  'defective.label.arrivalNote': 'Geliş Notu',
  'defective.label.note': 'Not',
  'defective.label.completedDate': 'Bitiş Tarihi',
  'defective.label.reportFormat': 'Rapor Formatı',
  'defective.label.reportPattern': 'Rapor Türü',
  'defective.label.repairedCount': 'Tamir Edilen Ürün Adeti',
  'defective.label.replacementCount': 'Değişen Ürün Adeti',
  'defective.label.refundCount': 'İade Alınan Ürün Adeti',
  'defective.label.outOfWarrantyCount': 'Garanti Dışı Ürün Adeti',
  'defective.label.unregisteredProductCount': 'Bizim Olmayan Ürün Adeti',
  'defective.label.intactProductCount': 'Sağlam Ürün Adeti',
  'defective.search.placeholder': 'Ara (Müşteri adı veya Ürün kodu)',
  'defective.select.processStatus.PENDING': 'Beklemede',
  'defective.select.processStatus.COMPLETED': 'Tamamlandı',
  'defective.select.process.REPAIRED': 'Tamir',
  'defective.select.process.REPLACEMENT': 'Değişme',
  'defective.select.process.REFUND': 'İade',
  'defective.select.process.OUT_OF_WARRANTY': 'Garanti Dışı',
  'defective.select.process.UNREGISTERED_PRODUCT': 'Kayıtlı Olmayan Ürün',
  'defective.select.process.INTACT_PRODUCT': 'Sağlam Ürün',
  'defective.select.reportFormat.PDF': 'PDF',
  'defective.select.reportFormat.XLS': 'Excel',
  'defective.select.reportPattern.REPORTER': 'Müşteri Bazlı Arızalı Ürünler',
  'defective.select.reportPattern.PENDING': 'Bekleyen Arızalı Ürünler',
  'defective.tabs.coreForm': 'Temel Bilgiler',
  'defective.tabs.recordForm': 'Kayıtlar',
  'defective.input.processStatus.placeholder': 'Arızalı durumu seçiniz',
  'defective.input.reporterName.placeholder': 'Müşteri seçiniz',
  'defective.input.productCode.placeholder': 'Ürün seçiniz',
  'defective.input.itemCount.placeholder': 'Adet',
  'defective.input.arrivalNote.placeholder': 'Geliş notu',
  'defective.input.reportFormat.placeholder': 'Rapor formatı',
  'defective.input.reportPattern.placeholder': 'Rapor türü',
  'defective.input.note.placeholder': 'Not',
  'defective.input.count.placeholder': 'Adet',
  'defective.input.reporterName.error': 'Lütfen müşteri seçiniz',
  'defective.input.productCode.error': 'Lütfen ürün seçiniz',
  'defective.input.itemCount.error': 'Lütfen arızalı ürün adeti giriniz',
  'defective.input.reportFormat.error': 'Lütfen rapor formatı seçiniz',
  'defective.input.reportPattern.error': 'Lütfen rapor türü seçiniz',
  'defective.tooltip.title': 'Müşteri adı veya ürün kodu ile arızalı kaydı arayabilirsiniz.',
  'defective.button.new': 'Arızalı Ürün Ekle',

  'banner.label.id': 'Id',
  'banner.label.url': 'Banner',
  'banner.label.prio': 'Öncelik',
  'banner.button.new': 'Banner Ekle',
  'banner.title.coreInformation': 'Temel Bilgiler',
  'banner.input.prio.placeholder': 'Banner önceliği',
  'banner.input.prio.error': 'Lütfen banner önceliğini seçiniz',

  'contactform.label.id': 'Id',
  'contactform.label.name': 'İsim',
  'contactform.label.email': 'Email',
  'contactform.label.phoneNe': 'Telefon',
  'contactform.label.subject': 'Konu',
  'contactform.label.message': 'Mesaj',
  'contactform.label.completed': 'Sonuçlanma Durumu',
  'contactform.label.createdDate': 'Yaratılma Tarihi',
  'contactform.label.createdDateStart': 'Yaratılma Tarihi (Başlangıç)',
  'contactform.label.createdDateEnd': 'Yaratılma Tarihi (Bitiş)',
  'contactform.label.select.completed': 'Sonuçlanma Durumu',
  'contactform.label.completed.true': 'Sonuçlandı',
  'contactform.label.completed.false': 'Sonuçlanmadı',
  'contactform.search.placeholder': 'Ara (İsim veya Email)',
  'contactform.select.completed.true': 'Sonuçlan Formlar',
  'contactform.select.completed.false': 'Sonuçlanmayan Formlar',
  'contactform.title.coreInformation': 'Temel Bilgiler',
  'contactform.tooltip.title': 'İsim veya email ile iletişim formu arayabilirsiniz.',

  'categoryGroup.label.id': 'Id',
  'categoryGroup.label.name': 'Grup Adı',
  'categoryGroup.label.prio': 'Öncelik',
  'categoryGroup.label.categoryIdList': 'Kategoriler',
  'categoryGroup.title.groupInformation': 'Grup Bilgileri',
  'categoryGroup.input.name.placeholder': 'Grup Adı',
  'categoryGroup.input.prio.placeholder': 'Öncelik',
  'categoryGroup.input.categoryIdList.placeholder': 'Kategoriler',
  'categoryGroup.input.name.error': 'Lütfen grup adını giriniz',
  'categoryGroup.input.prio.error': 'Lütfen önceliği giriniz',
  'categoryGroup.input.categoryIdList.error': 'Lütfen en az bir kategori giriniz',
  'categoryGroup.button.new': 'Kategori Grubu Ekle',

  'region.label.id': 'Id',
  'region.label.name': 'Depo Adı',
  'region.label.default': 'Varsayılan',
  'region.label.categoryIdList': 'Kategoriler',
  'region.label.select.default.true': 'Evet',
  'region.label.select.default.false': 'Hayır',
  'region.title.coreInformation': 'Temel Bilgiler',
  'region.input.name.placeholder': 'Depo Adı',
  'region.input.categoryIdList.placeholder': 'Kategoriler',
  'region.input.name.error': 'Lütfen depo adını giriniz',
  'region.button.new': 'Depo Ekle',

  'inventory.label.id': 'Id',
  'inventory.label.product': 'Ürün',
  'inventory.label.region': 'Depo',
  'inventory.label.quantity': 'Adet',
  'inventory.label.available': 'Stok Durumu',
  'inventory.label.available.true': 'Var',
  'inventory.label.available.false': 'Yok',
  'inventory.label.adjustmentReason': 'Neden',
  'inventory.label.adjustmentReason.InventoryRevaluation': 'Yeniden Düzenleme',
  'inventory.search.placeholder': 'Ara (Ürün kodu, açıklama veya tag)',
  'inventory.title.coreInformation': 'Temel Bilgiler',
  'inventory.input.product.placeholder': 'Ürün',
  'inventory.input.region.placeholder': 'Depo',
  'inventory.input.quantity.placeholder': 'Adet',
  'inventory.input.product.error': 'Lütfen ürün seçiniz',
  'inventory.input.region.error': 'Lütfen depo seçiniz',
  'inventory.input.quantity.error': 'Lütfen adet giriniz',
  'inventory.button.new': 'Stok Ekle',

  'statistics.label.order': 'Sipariş',
  'statistics.label.orderCount': 'Sipariş sayısı',
  'statistics.label.orderTotal': 'İskontolu Ciro',
  'statistics.label.orderSubTotal': 'İskontosuz Toplam Ciro',
  'statistics.label.cart': 'Sepet',
  'statistics.label.cartDaily': 'Şu anda aktif sepet sayısı',
  'statistics.label.productCode': 'En Çok Satan Ürünler',
  'statistics.label.productCategory': 'En Çok Satan Kategoriler',
  'statistics.label.count': 'Adet',
  'statistics.label.total': 'İskontolu Ciro',
  'statistics.label.subTotal': 'Toplam Ciro',
  'statistics.label.salesman': 'Pazarlamacı',

  'logging.title.files': 'Log Dosyaları',
  'logging.title.archive': 'Arşiv:',
  'logging.title.currentLog': 'Güncel Log',
  'logging.title.logContent': 'Log içeriği',
  'logging.input.downloadCurrentLog': 'Güncel dosyayı indirmek için tıklayın.',
  'logging.input.page': 'Sayfayı giriniz',
  'logging.input.search': 'Log arama',

  'mobile.android.button.new': 'APK Yükle',
  'mobile.android.label.version': 'Versiyon',
  'mobile.android.label.url': 'URL',
  'mobile.android.title.coreInformation': 'Temel Bilgiler',
  'mobile.android.input.version.error': 'Versiyon bilgisi boş olamaz!',
  'mobile.android.input.version.placeholder': '1.0.1',
  'mobile.android.label.file': 'APK Dosyası',
}

export default {
  locale: 'tr-TR',
  localeAntd,
  messages,
}
