import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { history } from 'index'
import { translate } from 'localization'
import { downloadFile } from 'helpers/file'
import * as productService from 'services/product'
import * as mediaService from 'services/product/media'
import actions from 'redux/product/actions'
import Product from 'model/product/Product'
import ProductList from 'model/product/ProductList'
import Media from 'model/product/Media'
import MediaList from 'model/product/MediaList'
import ListProductReq from 'model/product/req/ListProductReq'
import SearchProductReq from 'model/product/req/SearchProductReq'
import ExportProductByFieldReq from 'model/product/req/ExportProductByFieldReq'
import CreateProductReq from 'model/product/req/CreateProductReq'
import UpdateProductReq from 'model/product/req/UpdateProductReq'
import LoadProductLegacyReq from 'model/product/req/LoadProductLegacyReq'
import LoadProductCreateReq from 'model/product/req/LoadProductCreateReq'
import MigrateProductReq from 'model/product/req/MigrateProductReq'
import SaveProductTagReq from 'model/product/req/SaveProductTagReq'
import SaveProductRelationshipReq from 'model/product/req/SaveProductRelationshipReq'

export function* LIST_PRODUCT({ payload }) {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(productService.listProduct, ListProductReq.of(payload))
  if (data) {
    yield put({
      type: 'product/SET_STATE',
      payload: {
        list: ProductList.fromData(data),
        detail: Product.empty(),
      },
    })
  }

  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_PRODUCT({ payload }) {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { productId } = payload
  const data = yield call(productService.readProduct, productId)
  if (data) {
    yield put({
      type: 'product/SET_STATE',
      payload: {
        detail: Product.fromData(data),
      },
    })
  }

  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SEARCH_PRODUCT({ payload }) {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(productService.searchProduct, SearchProductReq.of(payload))
  if (data) {
    yield put({
      type: 'product/SET_STATE',
      payload: {
        list: ProductList.fromData(data),
        detail: Product.empty(),
      },
    })
  }

  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* EXPORT_BY_FIELD_PRODUCT({ payload }) {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(productService.exportProductByField, ExportProductByFieldReq.of(payload))
  if (data) {
    downloadFile(data.fileType, data.base64, data.fileName)
  }

  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_PRODUCT({ payload }) {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(productService.createProduct, CreateProductReq.of(payload))
  if (data) {
    yield put({
      type: 'product/ADD_STATE',
      payload: {
        product: Product.fromData(data),
      },
    })
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_PRODUCT({ payload }) {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(productService.updateProduct, UpdateProductReq.of(payload))
  if (success) {
    yield history.push('/catalog/product/view')
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_PRODUCT({ payload }) {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { productId } = payload
  const success = yield call(productService.deleteProduct, productId)
  if (success) {
    yield put({
      type: 'product/DELETE_STATE',
      payload: { productId },
    })
  }

  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_LEGACY_UPDATE({ payload, callback }) {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(productService.loadProductLegacy, LoadProductLegacyReq.of(payload))
  if (data) {
    callback.onSuccess()
  } else {
    callback.onError()
  }

  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* MIGRATE_PRODUCT_CODE({ payload, callback }) {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(productService.migrateProduct, MigrateProductReq.of(payload))
  if (data) {
    callback.onSuccess()
  } else {
    callback.onError()
  }

  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_CREATE({ payload, callback }) {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(productService.loadProductCreate, LoadProductCreateReq.of(payload))
  if (data) {
    callback.onSuccess()
  } else {
    callback.onError()
  }

  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SAVE_PRODUCT_TAG({ payload }) {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(productService.saveProductTag, SaveProductTagReq.of(payload))
  if (data) {
    yield put({
      type: 'product/SET_STATE',
      payload: {
        detail: Product.from(data),
      },
    })
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SAVE_PRODUCT_RELATIONSHIP({ payload }) {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(
    productService.saveProductRelationship,
    SaveProductRelationshipReq.of(payload),
  )
  if (data) {
    yield put({
      type: 'product/SET_STATE',
      payload: {
        detail: Product.from(data),
      },
    })
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LIST_MEDIA({ payload }) {
  const { productId } = payload
  const data = yield call(mediaService.listMedia, productId)
  if (data) {
    yield put({
      type: 'product/SET_STATE',
      payload: {
        mediaList: MediaList.fromData(data),
      },
    })
  }
}

export function* CREATE_MEDIA({ payload, mediaCallback }) {
  const { productId, media, prio } = payload
  const data = yield call(mediaService.createMedia, productId, media, prio)
  if (data) {
    mediaCallback.onSuccess()

    yield put({
      type: 'product/ADD_STATE',
      payload: {
        media: Media.fromData(data),
      },
    })
  } else {
    mediaCallback.onError()
  }
}

export function* DELETE_MEDIA({ payload, mediaCallback }) {
  const { mediaId } = payload
  const data = yield call(mediaService.deleteMedia, mediaId)
  if (data) {
    mediaCallback.onSuccess()

    yield put({
      type: 'product/DELETE_STATE',
      payload: {
        mediaId,
      },
    })
  } else {
    mediaCallback.onError()
  }
}

export function* DELETE_ALL_MEDIA({ payload }) {
  const { productId } = payload
  const data = yield call(mediaService.deleteAllMedia, productId)
  if (data) {
    yield put({
      type: 'product/SET_STATE',
      payload: {
        mediaList: MediaList.empty(),
      },
    })
  }
}

export function* LOAD_CREATE_SAMPLE_EXCEL() {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(productService.downloadSampleBulkCreateExcel)
  if (data) {
    downloadFile(data.fileType, data.base64, data.fileName)
  }

  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_LEGACY_UPDATE_SAMPLE_EXCEL() {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(productService.downloadSampleBulkLegacyUpdateExcel)
  if (data) {
    downloadFile(data.fileType, data.base64, data.fileName)
  }

  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_PRODUCT, LIST_PRODUCT),
    takeEvery(actions.READ_PRODUCT, READ_PRODUCT),
    takeEvery(actions.SEARCH_PRODUCT, SEARCH_PRODUCT),
    takeEvery(actions.EXPORT_BY_FIELD_PRODUCT, EXPORT_BY_FIELD_PRODUCT),
    takeEvery(actions.CREATE_PRODUCT, CREATE_PRODUCT),
    takeEvery(actions.UPDATE_PRODUCT, UPDATE_PRODUCT),
    takeEvery(actions.DELETE_PRODUCT, DELETE_PRODUCT),
    takeEvery(actions.LOAD_LEGACY_UPDATE, LOAD_LEGACY_UPDATE),
    takeEvery(actions.MIGRATE_PRODUCT_CODE, MIGRATE_PRODUCT_CODE),
    takeEvery(actions.LOAD_CREATE, LOAD_CREATE),
    takeEvery(actions.SAVE_PRODUCT_TAG, SAVE_PRODUCT_TAG),
    takeEvery(actions.SAVE_PRODUCT_RELATIONSHIP, SAVE_PRODUCT_RELATIONSHIP),
    takeEvery(actions.LIST_MEDIA, LIST_MEDIA),
    takeEvery(actions.CREATE_MEDIA, CREATE_MEDIA),
    takeEvery(actions.DELETE_MEDIA, DELETE_MEDIA),
    takeEvery(actions.DELETE_ALL_MEDIA, DELETE_ALL_MEDIA),
    takeEvery(actions.LOAD_CREATE_SAMPLE_EXCEL, LOAD_CREATE_SAMPLE_EXCEL),
    takeEvery(actions.LOAD_LEGACY_UPDATE_SAMPLE_EXCEL, LOAD_LEGACY_UPDATE_SAMPLE_EXCEL),
  ])
}
