export default class CreateAboutReq {
  constructor(companyName, address, phoneNo, faxNo, email, bankAccountNote, socialAccounts, googleMap, defaultt) {
    this.companyName = companyName
    this.address = address
    this.phoneNo = phoneNo
    this.faxNo = faxNo
    this.email = email
    this.bankAccountNote = bankAccountNote
    this.socialAccounts = socialAccounts
    this.googleMap = googleMap
    this.defaultt = defaultt
  }

  static of(data) {
    const { companyName, address, phoneNo, faxNo, email, bankAccountNote, socialAccounts, googleMap, defaultt } = data

    let isDefault = defaultt
    if (defaultt === null || defaultt === undefined) {
      isDefault = false
    }

    return new CreateAboutReq(companyName, address, phoneNo, faxNo, email, bankAccountNote, socialAccounts, googleMap, isDefault)
  }
}
