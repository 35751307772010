const urls = {
  location: {
    listByCountry: '/location/country/',
  },
  menu: {
    list: '/menu/user',
  },
  tenant: {
    read: '/tenant',
  },
  auth: {
    login: '/auth/login-admin',
    logout: '/auth/logout',
  },
  user: {
    list: '/admin/users',
    read: '/admin/users/{0}',
    search: '/admin/users/search',
    lock: '/admin/users/{0}/lock',
    create: '/admin/users',
    updatePersonal: '/admin/users/{0}/personal',
    updateRole: '/admin/users/{0}/role',
    updatePassword: '/admin/users/{0}/password',
    unlock: '/admin/users/{0}/unlock',
    enable: '/admin/users/{0}/enable',
    disable: '/admin/users/{0}/disable',
    currentRead: '/users/read',
    password: {
      forgot: '/users/password/forgot-password',
    },
    role: {
      list: '/admin/roles',
    },
  },
  customer: {
    list: '/admin/customers',
    read: '/admin/customers/',
    search: '/admin/customers/search',
    create: '/admin/customers',
    update: '/admin/customers',
    export: '/admin/customers/export',
  },
  customerGroup: {
    list: '/admin/customer-groups',
    search: '/admin/customer-groups/search',
    read: '/admin/customer-groups/',
    create: '/admin/customer-groups',
    update: '/admin/customer-groups',
    export: '/admin/customer-groups/export',
  },
  cart: {
    saveSettings: 'admin/carts/settings',
    readSettings: 'admin/carts/{0}/settings',
  },
  product: {
    list: 'admin/products',
    search: 'admin/products/search',
    read: 'admin/products/{0}',
    exportByField: 'admin/products/export-by-field',
    create: 'admin/products',
    update: 'admin/products',
    delete: 'admin/products/{0}',
    loadLegacyUpdate: 'admin/products/bulk/legacy-update',
    sampleBulkLegacyUpdateExcel: 'admin/products/bulk/sample-excel/LEGACY_UPDATE',
    loadCreate: 'admin/products/bulk/create',
    sampleBulkCreateExcel: 'admin/products/bulk/sample-excel/CREATE',
    migrateProduct: 'admin/migrate/product',
    saveTag: 'admin/products/tag',
    saveRelationship: 'admin/products/relationship',
    saveSettings: 'admin/products/settings',
    readSettings: 'admin/products/settings/{0}',
    category: {
      list: 'admin/products/category',
      listFlat: 'admin/products/category/flat',
      search: 'admin/products/category/search',
      read: 'admin/products/category/{0}',
      create: 'admin/products/category',
      update: 'admin/products/category',
      delete: 'admin/products/category/{0}',
      saveSettings: 'admin/products/category/settings',
      readSettings: 'admin/products/category/settings/{0}',
      export: '/admin/products/category/export',
    },
    categoryGroup: {
      list: 'admin/products/category/group',
      read: 'admin/products/category/group/{0}',
      create: 'admin/products/category/group',
      update: 'admin/products/category/group',
      delete: 'admin/products/category/group/{0}',
    },
    brand: {
      list: 'admin/products/brand',
      search: 'admin/products/brand/search',
      read: 'admin/products/brand/{0}',
      create: 'admin/products/brand',
      update: 'admin/products/brand',
      delete: 'admin/products/brand/{0}',
    },
    media: {
      list: 'admin/products/{0}/media',
      create: 'admin/products/{0}/media',
      remove: 'admin/products/media/{0}',
      removeAll: 'admin/products/{0}/media',
    },
    led: {
      list: 'admin/products/led',
      search: 'admin/products/led/search',
      read: 'admin/products/led/{0}',
      create: 'admin/products/led',
      update: 'admin/products/led',
      delete: 'admin/products/led/{0}',
      load: 'admin/products/led/load',
      sampleLoadExcel: 'admin/products/led/sample-load-excel',
      initialPage: 'products/led/page-data',
    },
  },
  audit: {
    list: 'admin/audit',
    read: 'admin/audit/{0}',
  },
  fault: {
    list: 'admin/fault-tracker',
    read: 'admin/fault-tracker/{0}',
  },
  news: {
    list: 'admin/news',
    search: 'admin/news/search',
    read: 'admin/news/{0}',
    create: 'admin/news',
    update: 'admin/news',
    delete: 'admin/news/{0}',
  },
  about: {
    list: 'admin/about',
    read: 'admin/about/{0}',
    create: 'admin/about',
    update: 'admin/about',
    delete: 'admin/about/{0}',
  },
  cache: {
    list: 'admin/cache',
    clearAll: 'admin/cache/delete-all',
    clearByName: 'admin/cache/name/{0}',
    clearByKey: 'admin/cache/name/{0}/key/{1}',
  },
  order: {
    list: 'admin/orders',
    read: 'admin/orders/{0}',
    export: 'admin/orders/{0}/export',
    createDirect: 'admin/orders/direct',
    deliver: 'admin/orders/{0}/deliver',
    cancel: 'admin/orders/{0}/cancel',
    delete: 'admin/orders/{0}',
    delivery: {
      list: 'orders/delivery',
      search: 'orders/delivery/search',
      read: 'orders/delivery/{0}',
      create: 'admin/orders/delivery',
      update: 'admin/orders/delivery',
      delete: 'admin/orders/delivery/{0}',
    },
  },
  discount: {
    list: 'admin/discounts',
    search: 'admin/discounts/search',
    read: 'admin/discounts/{0}',
    create: 'admin/discounts',
    update: 'admin/discounts',
    delete: 'admin/discounts/{0}',
    load: 'admin/discounts/load',
    sampleLoadExcel: 'admin/discounts/sample-load-excel',
  },
  defective: {
    list: 'admin/defectives',
    read: 'admin/defectives/{0}',
    search: 'admin/defectives/search',
    export: 'admin/defectives/export',
    create: 'admin/defectives',
    update: 'admin/defectives',
    delete: 'admin/defectives/{0}',
    complete: 'admin/defectives/{0}/complete',
    associate: 'admin/defectives/{0}/associate-record',
  },
  ecom: {
    banner: {
      list: 'admin/ecom/banner',
      create: 'admin/ecom/banner',
      update: 'admin/ecom/banner',
      delete: 'admin/ecom/banner/{0}',
    },
    contactform: {
      list: 'admin/ecom/contactform',
      search: 'admin/ecom/contactform/search',
      read: 'admin/ecom/contactform/{0}',
      complete: 'admin/ecom/contactform/{0}/complete',
      delete: 'admin/ecom/contactform/{0}',
    },
  },
  inventory: {
    list: 'admin/inventory',
    listHistory: 'admin/inventory/history/{0}',
    search: 'admin/inventory/search',
    read: 'admin/inventory/{0}',
    create: 'admin/inventory',
    adjust: 'admin/inventory/adjust',
    delete: 'admin/inventory/{0}',
    region: {
      list: 'admin/inventory/region',
      read: 'admin/inventory/region/{0}',
      create: 'admin/inventory/region/',
      update: 'admin/inventory/region/',
      delete: 'admin/inventory/region/{0}',
    },
  },
  statistics: {
    order: {
      all: 'admin/statistics/order/all',
    },
  },
  toggle: {
    list: 'admin/toggle',
    read: 'admin/toggle/{0}',
    create: 'admin/toggle',
    update: 'admin/toggle',
    delete: 'admin/toggle/{0}',
  },
  logging: {
    downloadCurrent: 'logging/download/current',
    downloadArchived: 'logging/download/archived/{0}',
    listArchived: 'logging/archived-files',
    readCurrent: 'logging/current/{0}',
    searchCurrent: 'logging/search',
  },
  mobile: {
    listAndroidDistribution: "admin/mobile/android",
    getAndroidDistributionUrl: "admin/mobile/android/{0}",
    uploadAndroidDistribution: "admin/mobile/android/upload/{0}",
  }
}

export default urls
