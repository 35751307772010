import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import {format} from 'helpers/string'
import qs from 'qs'

export async function downloadCurrent() {
  return apiClient
    .get(urls.logging.downloadCurrent)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}

export async function downloadArchived(name) {
  const url = format(urls.logging.downloadArchived, [name])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}

export async function listArchived() {
  return apiClient
    .get(urls.logging.listArchived)
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(() => console.log())
}

export async function readCurrentLog(page) {
  const url = format(urls.logging.readCurrent, [page])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(() => console.log())
}

export async function searchCurrentLog(req) {
  return apiClient
    .get(urls.logging.searchCurrent, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(() => console.log())
}
