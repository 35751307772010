import { all } from 'redux-saga/effects'

import settings from 'redux/settings/sagas'
import place from 'redux/place/sagas'
import auth from 'redux/auth/sagas'
import menu from 'redux/menu/sagas'
import customer from 'redux/customer/sagas'
import customerGroup from 'redux/customer-group/sagas'
import user from 'redux/user/sagas'
import userSettings from 'redux/userSettings/sagas'
import category from 'redux/category/sagas'
import brand from 'redux/brand/sagas'
import product from 'redux/product/sagas'
import led from 'redux/led/sagas'
import audit from 'redux/audit/sagas'
import fault from 'redux/fault/sagas'
import news from 'redux/news/sagas'
import cache from 'redux/cache/sagas'
import about from 'redux/about/sagas'
import delivery from 'redux/delivery/sagas'
import discount from 'redux/discount/sagas'
import defective from 'redux/defective/sagas'
import banner from 'redux/banner/sagas'
import contactform from 'redux/contactform/sagas'
import categoryGroup from 'redux/category-group/sagas'
import inventory from 'redux/inventory/sagas'
import order from 'redux/order/sagas'
import statistics from 'redux/statistics/sagas'
import tenant from 'redux/tenant/sagas'
import toggle from 'redux/toggle/sagas'
import logging from 'redux/logging/sagas'
import mobile from 'redux/mobile/sagas'

export default function* rootSaga() {
  yield all([
    settings(),
    place(),
    auth(),
    menu(),
    customer(),
    customerGroup(),
    user(),
    userSettings(),
    category(),
    brand(),
    product(),
    led(),
    audit(),
    fault(),
    news(),
    cache(),
    about(),
    delivery(),
    discount(),
    defective(),
    banner(),
    contactform(),
    categoryGroup(),
    inventory(),
    order(),
    statistics(),
    tenant(),
    toggle(),
    logging(),
    mobile(),
  ])
}
