const actions = {
  INIT_LED: 'led/INIT_LED',
  LIST_LED: 'led/LIST_LED',
  READ_LED: 'led/READ_LED',
  SEARCH_LED: 'led/SEARCH_LED',
  CREATE_LED: 'led/CREATE_LED',
  UPDATE_LED: 'led/UPDATE_LED',
  DELETE_LED: 'led/DELETE_LED',
  LOAD_LED: 'led/LOAD_LED',
  SAMPLE_EXCEL: 'led/SAMPLE_EXCEL',
  SET_STATE: 'led/SET_STATE',
  ADD_STATE: 'led/ADD_STATE',
  DELETE_STATE: 'led/DELETE_STATE',
}

export default actions
