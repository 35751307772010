export default class LoadDiscountReq {
  constructor(file) {
    this.file = file
  }

  static of(payload) {
    const { file } = payload
    return new LoadDiscountReq(file)
  }
}
