import Price from 'model/product/Price'

export default class OrderStatistics {
  constructor(
    prettyBeginDate,
    prettyUntilDate,
    salesmanStats,
    userStats,
    totalCount,
    sumOfChargeTotal,
    sumOfChargeSubTotal,
    curr,
    currSymbol,
    top5ProductCodeStatistics,
    top5ProductCategoryStatistics,
    top5ProductBrandStatistics
  ) {
    this.prettyBeginDate = prettyBeginDate
    this.prettyUntilDate = prettyUntilDate
    this.salesmanStats = salesmanStats
    this.userStats = userStats
    this.totalCount = totalCount
    this.sumOfChargeTotal = sumOfChargeTotal
    this.sumOfChargeSubTotal = sumOfChargeSubTotal
    this.curr = curr
    this.currSymbol = currSymbol
    this.top5ProductCodeStatistics = top5ProductCodeStatistics
    this.top5ProductCategoryStatistics = top5ProductCategoryStatistics
    this.top5ProductBrandStatistics = top5ProductBrandStatistics
  }

  static empty() {
    return new OrderStatistics('', '', [], [], 0, 0, 0, '', '', [], [], [])
  }

  static from(data) {
    const {
      prettyBeginDate,
      prettyUntilDate,
      salesmanStats,
      userStats,
      totalCount,
      sumOfChargeTotal,
      sumOfChargeSubTotal,
      curr,
      currSymbol,
      top5ProductCodeStatistics,
      top5ProductCategoryStatistics,
      top5ProductBrandStatistics,
    } = data
    return new OrderStatistics(
      prettyBeginDate,
      prettyUntilDate,
      salesmanStats,
      userStats,
      totalCount,
      sumOfChargeTotal,
      sumOfChargeSubTotal,
      curr,
      currSymbol,
      top5ProductCodeStatistics,
      top5ProductCategoryStatistics,
      top5ProductBrandStatistics
    )
  }

  getChargeTotalWithCurr() {
    return new Price(this.sumOfChargeTotal, this.curr).toPrettyString()
  }

  getChargeSubTotalWithCurr() {
    return new Price(this.sumOfChargeSubTotal, this.curr).toPrettyString()
  }
}
