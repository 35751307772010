import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Layout } from 'antd'
import classNames from 'classnames'
import { translate } from 'localization'

import style from 'layouts/Auth/style.module.scss'

const mapStateToProps = () => ({})

const AuthLayout = ({ children }) => {
  return (
    <Layout>
      <Layout.Content>
        <div className={classNames(`${style.container}`)}>
          <div className={classNames(`${style.topbar}`)}>
            <div className={style.logoContainer}>
              <div className={style.logo}>
                <img src="resources/images/logo.png" className="me-2" alt="TSS" />
                <div className={style.name}>{translate('app.name')}</div>
              </div>
            </div>
          </div>
          <div className={style.containerInner}>{children}</div>
          <div className="mt-auto pb-5 pt-5">
            <div className="text-center">Copyright © 2017 - 2024 TSS</div>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(AuthLayout))
