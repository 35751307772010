import actions from 'redux/logging/actions'
import LogLines from 'model/logging/LogLines'

const initialState = {
  archivedList: [],
  logLines: LogLines.empty(),
  loading: false
}

export default function loggingReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}
