import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { history } from 'index'
import { translate } from 'localization'
import { downloadFile } from 'helpers/file'
import * as categoryService from 'services/product/category'
import actions from 'redux/category/actions'
import Category from 'model/product/Category'
import CategoryList from 'model/product/CategoryList'
import ListCategoryReq from 'model/product/req/ListCategoryReq'
import SearchCategoryReq from 'model/product/req/SearchCategoryReq'
import ExportCategoryReq from 'model/product/req/ExportCategoryReq'
import CreateCategoryReq from 'model/product/req/CreateCategoryReq'
import UpdateCategoryReq from 'model/product/req/UpdateCategoryReq'

export function* LIST_CATEGORY({ payload }) {
  yield put({
    type: 'category/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(categoryService.listCategory, ListCategoryReq.of(payload))
  if (data) {
    yield put({
      type: 'category/SET_STATE',
      payload: {
        list: CategoryList.fromData(data),
        listParent: CategoryList.fromData(data),
        detail: Category.empty()
      }
    })
  }

  yield put({
    type: 'category/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LIST_FLAT_CATEGORY({ payload }) {
  yield put({
    type: 'category/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(categoryService.listFlatCategory, ListCategoryReq.of(payload))
  if (data) {
    yield put({
      type: 'category/SET_STATE',
      payload: {
        listFlat: CategoryList.fromData(data)
      }
    })
  }

  yield put({
    type: 'category/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LIST_PARENT_CATEGORY() {
  const req = new ListCategoryReq()
  req.showRootCategory = true

  const data = yield call(categoryService.listCategory, req)
  if (data) {
    yield put({
      type: 'category/SET_STATE',
      payload: {
        listParent: CategoryList.fromData(data),
      }
    })
  }
}

export function* READ_CATEGORY({ payload }) {
  yield put({
    type: 'category/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { categoryId } = payload
  const data = yield call(categoryService.readCategory, categoryId)
  if (data) {
    yield put({
      type: 'category/SET_STATE',
      payload: {
        detail: Category.fromData(data)
      }
    })
  }

  yield put({
    type: 'category/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SEARCH_PARENT_CATEGORY({ payload }) {
  yield put({
    type: 'category/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(categoryService.searchCategory, SearchCategoryReq.of(payload))
  if (data) {
    yield put({
      type: 'category/SET_STATE',
      payload: {
        listParent: CategoryList.fromData(data),
        detail: Category.empty()
      }
    })
  }

  yield put({
    type: 'category/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* EXPORT_CATEGORY({ payload }) {
  yield put({
    type: 'category/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(categoryService.exportCategory, ExportCategoryReq.of(payload))
  if (data) {
    downloadFile(data.fileType, data.base64, data.fileName)
  }

  yield put({
    type: 'category/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_CATEGORY({ payload }) {
  yield put({
    type: 'category/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(categoryService.createCategory, CreateCategoryReq.of(payload))
  if (data) {
    yield put({
      type: 'category/ADD_STATE',
      payload: {
        category: Category.fromData(data)
      }
    })
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'category/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_CATEGORY({ payload }) {
  yield put({
    type: 'category/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(categoryService.updateCategory, UpdateCategoryReq.of(payload))
  if (success) {
    yield history.push('/catalog/category/view')
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'category/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_CATEGORY({ payload }) {
  yield put({
    type: 'category/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { categoryId } = payload
  const success = yield call(categoryService.deleteCategory, categoryId)
  if (success) {
    yield put({
      type: 'category/DELETE_STATE',
      payload: { categoryId }
    })
  }

  yield put({
    type: 'category/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_CATEGORY, LIST_CATEGORY),
    takeEvery(actions.LIST_FLAT_CATEGORY, LIST_FLAT_CATEGORY),
    takeEvery(actions.LIST_PARENT_CATEGORY, LIST_PARENT_CATEGORY),
    takeEvery(actions.READ_CATEGORY, READ_CATEGORY),
    takeEvery(actions.SEARCH_PARENT_CATEGORY, SEARCH_PARENT_CATEGORY),
    takeEvery(actions.EXPORT_CATEGORY, EXPORT_CATEGORY),
    takeEvery(actions.CREATE_CATEGORY, CREATE_CATEGORY),
    takeEvery(actions.UPDATE_CATEGORY, UPDATE_CATEGORY),
    takeEvery(actions.DELETE_CATEGORY, DELETE_CATEGORY),
  ])
}
