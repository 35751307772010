export default class SearchDiscountRuleReq {
  constructor(searchText) {
    this.searchText = searchText
  }

  static of(data) {
    const { searchText } = data
    return new SearchDiscountRuleReq(searchText)
  }
}
