import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'

export default async function getMenuData() {
  return apiClient
    .get(urls.menu.list)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => console.log())
}
