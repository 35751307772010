export default class LogLines {
  constructor(logs) {
    this.logs = logs
  }

  static empty() {
    return new LogLines([])
  }

  static fromData(data) {
    return new LogLines(data.logLines)
  }
}
