import Distribution from 'model/mobile/Distribution'

export default class DistributionList {
  constructor(distributions, totalCount) {
    this.distributions = distributions
    this.totalCount = totalCount
  }

  static empty() {
    return new DistributionList([], 0)
  }

  static fromData(data) {
    return new DistributionList(
      data.distributionList.map((d) => Distribution.from(d)),
      data.distributionList.length,
    )
  }
}
