import apiClient from 'services/axios'

import urls from 'services/axios/ApiUrl'
import { format } from 'helpers/string'
import { toFormData } from 'helpers/form'

export async function listBrand() {
  return apiClient
    .get(urls.product.brand.list)
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(() => console.log())
}

export async function readBrand(brandId) {
  const url = format(urls.product.brand.read, [brandId])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}

export async function searchBrand(req) {
  return apiClient
    .get(urls.product.brand.search, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(() => console.log())
}

export async function createBrand(req) {
  const formData = toFormData(req)

  return apiClient
    .post(urls.product.brand.create, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}

export async function updateBrand(req) {
  const formData = toFormData(req)

  return apiClient
    .put(urls.product.brand.update, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then((response) => {
      return !!response
    })
    .catch(() => console.log())
}

export async function deleteBrand(brandId) {
  const url = format(urls.product.brand.delete, [brandId])

  return apiClient
    .delete(url)
    .then((response) => {
      return !!response
    })
    .catch(() => console.log())
}

