const actions = {
  DOWNLOAD_CURRENT: 'logging/DOWNLOAD_CURRENT',
  DOWNLOAD_ARCHIVED: 'logging/DOWNLOAD_ARCHIVED',
  LIST_ARCHIVED: 'logging/LIST_ARCHIVED',
  READ_CURRENT: 'logging/READ_CURRENT',
  SEARCH_CURRENT: 'logging/SEARCH_CURRENT',
  SET_STATE: 'logging/SET_STATE',
}

export default actions
