import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import { format } from 'helpers/string'

export async function listToggle(req) {
  return apiClient
    .get(urls.toggle.list, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(() => console.log())
}

export async function readToggle(toggleKey) {
  const url = format(urls.toggle.read, [toggleKey])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}

export async function createToggle(req) {
  return apiClient
    .post(urls.toggle.create, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}

export async function updateToggle(req) {
  return apiClient
    .put(urls.toggle.update, req)
    .then(response => {
      return !!response
    })
    .catch(() => console.log())
}

export async function deleteToggle(toggleKey) {
  const url = format(urls.toggle.delete, [toggleKey])

  return apiClient
    .delete(url)
    .then(response => {
      return !!response
    })
    .catch(() => console.log())
}
