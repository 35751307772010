import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import { format } from 'helpers/string'

export async function listMedia(productId) {
  const url = format(urls.product.media.list, [productId])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(() => console.log())
}

export async function createMedia(productId, media, prio) {
  const url = format(urls.product.media.create, [productId])

  const formData = new FormData()
  formData.append("content", media)
  formData.append("prio", prio)

  return apiClient
    .post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}

export async function deleteMedia(mediaId) {
  const url = format(urls.product.media.remove, [mediaId])

  return apiClient
    .delete(url)
    .then((response) => {
      return !!response
    })
    .catch(() => console.log())
}

export async function deleteAllMedia(productId) {
  const url = format(urls.product.media.removeAll, [productId])

  return apiClient
    .delete(url)
    .then((response) => {
      return !!response
    })
    .catch(() => console.log())
}

