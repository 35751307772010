import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import settings from 'redux/settings/reducers'
import place from 'redux/place/reducers'
import auth from 'redux/auth/reducers'
import menu from 'redux/menu/reducers'
import customer from 'redux/customer/reducers'
import customerGroup from 'redux/customer-group/reducers'
import user from 'redux/user/reducers'
import userSettings from 'redux/userSettings/reducers'
import category from 'redux/category/reducers'
import brand from 'redux/brand/reducers'
import product from 'redux/product/reducers'
import led from 'redux/led/reducers'
import audit from 'redux/audit/reducers'
import fault from 'redux/fault/reducers'
import news from 'redux/news/reducers'
import cache from 'redux/cache/reducers'
import about from 'redux/about/reducers'
import delivery from 'redux/delivery/reducers'
import discount from 'redux/discount/reducers'
import defective from 'redux/defective/reducers'
import banner from 'redux/banner/reducers'
import contactform from 'redux/contactform/reducers'
import categoryGroup from 'redux/category-group/reducers'
import inventory from 'redux/inventory/reducers'
import order from 'redux/order/reducers'
import statistics from 'redux/statistics/reducers'
import tenant from 'redux/tenant/reducers'
import toggle from 'redux/toggle/reducers'
import logging from 'redux/logging/reducers'
import mobile from 'redux/mobile/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    settings,
    place,
    auth,
    menu,
    customer,
    customerGroup,
    user,
    userSettings,
    category,
    brand,
    product,
    led,
    audit,
    fault,
    news,
    cache,
    about,
    delivery,
    discount,
    defective,
    banner,
    contactform,
    categoryGroup,
    inventory,
    order,
    statistics,
    tenant,
    toggle,
    logging,
    mobile,
  })
