const actions = {
  LIST_PRODUCT: 'product/LIST_PRODUCT',
  READ_PRODUCT: 'product/READ_PRODUCT',
  SEARCH_PRODUCT: 'product/SEARCH_PRODUCT',
  EXPORT_BY_FIELD_PRODUCT: 'product/EXPORT_BY_FIELD_PRODUCT',
  CREATE_PRODUCT: 'product/CREATE_PRODUCT',
  UPDATE_PRODUCT: 'product/UPDATE_PRODUCT',
  DELETE_PRODUCT: 'product/DELETE_PRODUCT',
  LOAD_LEGACY_UPDATE: 'product/LOAD_LEGACY_UPDATE',
  LOAD_LEGACY_UPDATE_SAMPLE_EXCEL: 'product/LOAD_LEGACY_UPDATE_SAMPLE_EXCEL',
  MIGRATE_PRODUCT_CODE: 'product/MIGRATE_PRODUCT_CODE',
  LOAD_CREATE: 'product/LOAD_CREATE',
  LOAD_CREATE_SAMPLE_EXCEL: 'product/LOAD_CREATE_SAMPLE_EXCEL',
  SAVE_PRODUCT_TAG: 'product/SAVE_PRODUCT_TAG',
  SAVE_PRODUCT_RELATIONSHIP: 'product/SAVE_PRODUCT_RELATIONSHIP',
  LIST_MEDIA: 'product/LIST_MEDIA',
  CREATE_MEDIA: 'product/CREATE_MEDIA',
  DELETE_MEDIA: 'product/DELETE_MEDIA',
  DELETE_ALL_MEDIA: 'product/DELETE_ALL_MEDIA',
  SET_STATE: 'product/SET_STATE',
  ADD_STATE: 'product/ADD_STATE',
  DELETE_STATE: 'product/DELETE_STATE',
}

export default actions
