import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import qs from "qs";

export async function listCustomer(req) {
  return apiClient
    .get(urls.customer.list, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(() => console.log())
}

export async function readCustomer(customerId) {
  return apiClient
    .get(urls.customer.read + customerId)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}

export async function searchCustomer(req) {
  return apiClient
    .get(urls.customer.search, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(() => console.log())
}

export async function exportCustomer(req) {
  return apiClient
    .get(urls.customer.export, {
      timeout: 60000,
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}

export async function createCustomer(req) {
  return apiClient
    .post(urls.customer.create, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}

export async function updateCustomer(req) {
  return apiClient
    .put(urls.customer.update, req)
    .then((response) => {
      return !!response
    })
    .catch(() => console.log())
}

export async function deleteCustomer(customerId) {
  return apiClient
    .delete(urls.customer.read + customerId)
    .then((response) => {
      return !!response
    })
    .catch(() => console.log())
}
