export default class UploadAndroidDistributionReq {
  constructor(file, version) {
    this.file = file
    this.version = version
  }

  static of(payload) {
    const { file, version } = payload
    return new UploadAndroidDistributionReq(file, version)
  }
}
