export default class CreateDirectOrderReq {
  constructor(
    username,
    customerId,
    cartType,
    platform,
    inventoryRegion,
    lineItems,
    deliveryId,
    note,
    paymentNote,
    fromSalesman,
  ) {
    this.username = username
    this.customerId = customerId
    this.cartType = cartType
    this.platform = platform
    this.inventoryRegion = inventoryRegion
    this.lineItems = lineItems
    this.deliveryId = deliveryId
    this.note = note
    this.paymentNote = paymentNote
    this.fromSalesman = fromSalesman
  }

  static of(data) {
    const {
      username,
      customerId,
      cartType,
      platform,
      inventoryRegion,
      lineItems,
      deliveryId,
      note,
      paymentNote,
      fromSalesman,
    } = data
    return new CreateDirectOrderReq(
      username,
      customerId,
      cartType || 'CART',
      platform || 'ADMIN',
      inventoryRegion,
      lineItems,
      deliveryId,
      note,
      paymentNote,
      fromSalesman || true,
    )
  }
}
