export default class Distribution {
  constructor(url, version) {
    this.url = url
    this.version = version
  }

  static empty() {
    return new Distribution("", "")
  }

  static fromData(data) {
    return this.from(data.distributionList)
  }

  static from(data) {
    const { url, version } = data
    return new Distribution(url, version)
  }
}
