import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import { format } from 'helpers/string'
import { toFormData } from 'helpers/form'

export async function listAndroidDistribution() {
  return apiClient
    .get(urls.mobile.listAndroidDistribution)
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(() => console.log())
}

export async function getAndroidDistributionUrl(version) {
  const url = format(urls.mobile.getAndroidDistributionUrl, [version])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(() => console.log())
}

export async function uploadAndroidDistribution(req) {
  const url = format(urls.mobile.uploadAndroidDistribution, [req.version])
  const formData = toFormData(req)

  return apiClient
    .post(url, formData, {
      timeout: 120000,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(response => {
      return !!response
    })
    .catch(() => console.log())
}
