import qs from 'qs'

import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import { format } from 'helpers/string'

export async function listDefectiveClaim(req) {
  return apiClient
    .get(urls.defective.list, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(() => console.log())
}

export async function searchDefectiveClaim(req) {
  return apiClient
    .get(urls.defective.search, {
      params: req,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(() => console.log())
}

export async function readDefectiveClaim(claimId) {
  const url = format(urls.defective.read, [claimId])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}

export async function exportDefectiveClaim(req) {
  return apiClient
    .get(urls.defective.export, {
      timeout: 60000,
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}

export async function createDefectiveClaim(req) {
  return apiClient
    .post(urls.defective.create, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}

export async function updateDefectiveClaim(req) {
  return apiClient
    .put(urls.defective.update, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}

export async function deleteDefectiveClaim(claimId) {
  const url = format(urls.defective.delete, [claimId])

  return apiClient
    .delete(url)
    .then(response => {
      if (response) {
        return !!response
      }
      return null
    })
    .catch(() => console.log())
}

export async function completeDefectiveClaim(claimId, req) {
  const url = format(urls.defective.complete, [claimId])

  return apiClient
    .put(url, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}

export async function associateRecordToDefectiveClaim(claimId, req) {
  const url = format(urls.defective.associate, [claimId])

  return apiClient
    .put(url, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}
