import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import { format } from 'helpers/string'

export async function readCartUserSettings(username) {
  const url = format(urls.cart.readSettings, [username])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}

export async function saveCartUserSettings(req) {
  return apiClient
    .post(urls.cart.saveSettings, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(() => console.log())
}
