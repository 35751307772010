import { all, call, put, takeEvery } from 'redux-saga/effects'

import * as mobileService from 'services/mobile'
import actions from 'redux/mobile/actions'
import DistributionList from 'model/mobile/DistributionList'
import UploadAndroidDistributionReq from 'model/mobile/req/UploadAndroidDistributionReq'

export function* LIST_ANDROID_DISTRIBUTION() {
  yield put({
    type: 'mobile/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(mobileService.listAndroidDistribution)
  if (data) {
    yield put({
      type: 'mobile/SET_STATE',
      payload: {
        list: DistributionList.fromData(data),
      }
    })
  }

  yield put({
    type: 'mobile/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_ANDROID_DISTRIBUTION_URL({ payload }) {
  yield put({
    type: 'mobile/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { version } = payload
  const data = yield call(mobileService.getAndroidDistributionUrl, version)
  if (data) {
    yield put({
      type: 'mobile/SET_STATE',
      payload: {
        detail: data.url
      }
    })
  }

  yield put({
    type: 'mobile/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPLOAD_ANDROID_DISTRIBUTION({ payload, callback }) {
  yield put({
    type: 'mobile/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(mobileService.uploadAndroidDistribution, UploadAndroidDistributionReq.of(payload))
  if (data) {
    callback.onSuccess()
  } else {
    callback.onError()
  }

  yield put({
    type: 'mobile/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_ANDROID_DISTRIBUTION, LIST_ANDROID_DISTRIBUTION),
    takeEvery(actions.GET_ANDROID_DISTRIBUTION_URL, GET_ANDROID_DISTRIBUTION_URL),
    takeEvery(actions.UPLOAD_ANDROID_DISTRIBUTION, UPLOAD_ANDROID_DISTRIBUTION),
  ])
}
