import actions from 'redux/statistics/actions'
import OrderStatistics from 'model/statistics/OrderStatistics'

const initialState = {
  order: OrderStatistics.empty(),
  loading: false,
}

export default function statisticsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}
