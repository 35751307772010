import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import style from 'components/cleanui/layout/Footer/style.module.scss'

import packageJson from '../../../../../package.json'

const mapStateToProps = ({ tenant }) => ({
  tenant: tenant.detail,
})

const Footer = ({ tenant }) => {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <a rel="noopener noreferrer" className={style.logo}>
          {tenant.name}
          <span />
        </a>
        <br />
        <p className="mb-0">Copyright © 2017 - 2024 TSS</p>
        <p className="mb-0 font-size-10">Version {packageJson.version}</p>
      </div>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(Footer))
