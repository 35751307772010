import DefectiveReporter from 'model/defective/DefectiveReporter'
import DefectiveProduct from 'model/defective/DefectiveProduct'
import DefectiveRecord from 'model/defective/DefectiveRecord'

export default class DefectiveClaim {
  constructor(
    id,
    reporter,
    product,
    itemCount,
    arrivalNote,
    note,
    arrival,
    processStatus,
    claimDate,
    prettyClaimDate,
    completedDate,
    prettyCompletedDate,
    records,
  ) {
    this.id = id
    this.reporter = reporter
    this.product = product
    this.itemCount = itemCount
    this.arrivalNote = arrivalNote
    this.note = note
    this.arrival = arrival
    this.processStatus = processStatus
    this.claimDate = claimDate
    this.prettyClaimDate = prettyClaimDate
    this.completedDate = completedDate
    this.prettyCompletedDate = prettyCompletedDate
    this.records = records
  }

  static ARRIVAL_TYPE = {
    CARGO: 'CARGO',
    E_COMMERCE: 'E_COMMERCE',
    MOBILE: 'MOBILE',
  }

  static ARRIVAL_TYPES = [
    this.ARRIVAL_TYPE.CARGO,
    this.ARRIVAL_TYPE.E_COMMERCE,
    this.ARRIVAL_TYPE.MOBILE,
  ]

  static PROCESS_TYPE = {
    REPAIRED: 'REPAIRED',
    REPLACEMENT: 'REPLACEMENT',
    REFUND: 'REFUND',
    OUT_OF_WARRANTY: 'OUT_OF_WARRANTY',
    UNREGISTERED_PRODUCT: 'UNREGISTERED_PRODUCT',
    INTACT_PRODUCT: 'INTACT_PRODUCT',
  }

  static PROCESS_TYPES = [
    this.PROCESS_TYPE.REPAIRED,
    this.PROCESS_TYPE.REPLACEMENT,
    this.PROCESS_TYPE.REFUND,
    this.PROCESS_TYPE.OUT_OF_WARRANTY,
    this.PROCESS_TYPE.UNREGISTERED_PRODUCT,
    this.PROCESS_TYPE.INTACT_PRODUCT,
  ]

  static STATUS = {
    PENDING: 'PENDING',
    COMPLETED: 'COMPLETED',
  }

  static STATUS_LIST = [this.STATUS.PENDING, this.STATUS.COMPLETED]

  static REPORT_FORMAT = {
    PDF: 'PDF',
    XLS: 'XLS',
  }

  static REPORT_FORMAT_LIST = [this.REPORT_FORMAT.PDF, this.REPORT_FORMAT.XLS]

  static REPORT_PATTERN = {
    REPORTER: 'REPORTER',
    PENDING: 'PENDING',
  }

  static REPORT_PATTERN_LIST = [this.REPORT_PATTERN.REPORTER, this.REPORT_PATTERN.PENDING]

  static empty() {
    return new DefectiveClaim(
      0,
      DefectiveReporter.empty(),
      DefectiveProduct.empty(),
      0,
      '',
      '',
      '',
      '',
      '',
      [],
    )
  }

  static fromData(data) {
    return this.from(data.claim)
  }

  static from(data) {
    const {
      id,
      reporter,
      product,
      itemCount,
      arrivalNote,
      note,
      arrival,
      processStatus,
      claimDate,
      prettyClaimDate,
      completedDate,
      prettyCompletedDate,
      records,
    } = data
    return new DefectiveClaim(
      id,
      reporter ? DefectiveReporter.from(reporter) : DefectiveReporter.empty(),
      product ? DefectiveProduct.from(product) : DefectiveProduct.empty(),
      itemCount,
      arrivalNote,
      note,
      arrival,
      processStatus,
      claimDate,
      prettyClaimDate,
      completedDate,
      prettyCompletedDate,
      (records || []).map(record => DefectiveRecord.from(record)),
    )
  }

  getRecordCount = processType => {
    const found = this.records.filter(r => r.process === processType)
    if (found.length > 0) {
      return found[0].count
    }

    return 0
  }

  isCompleted = () => {
    return this.processStatus && this.processStatus === 'COMPLETED'
  }
}
